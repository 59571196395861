import React, { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";

const Header = () => {
    const location = useLocation();

    const [navbarMobile, setNavbarMobile] = useState(false);
    const [listIcon, setListIcon] = useState(true);

    const handleToggle = () => {
        setNavbarMobile((prevNavbarMobile) => !prevNavbarMobile);
        setListIcon((prevListIcon) => !prevListIcon);
    };

    useEffect(() => {
        /**
         * Scrolls to an element with header offset
         */
        const scrollto = (el) => {
            let header = document.getElementById('header');
            let offset = header.offsetHeight;

            if (!header.classList.contains('header-scrolled')) {
                offset -= 10;
            }

            let elementPos = document.getElementById(el).offsetTop;
            window.scrollTo({
                top: elementPos - offset,
                behavior: 'smooth',
            });
        };

        /**
         * Toggle .header-scrolled class to #header when page is scrolled
         */
        const headerScrolled = () => {
            let header = document.getElementById('header');
            if (window.scrollY > 100) {
                header.classList.add('header-scrolled');
            } else {
                header.classList.remove('header-scrolled');
            }
        };

        // Event listener for window load
        window.addEventListener('load', headerScrolled);

        // Event listener for scroll
        window.addEventListener('scroll', headerScrolled);

        // Cleanup
        return () => {
            window.removeEventListener('load', headerScrolled);
            window.removeEventListener('scroll', headerScrolled);
        };
    }, []);

    return (
        <>
            {/* ======= Header ======= */}
            <header id="header" className="header fixed-top">
                <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
                    <Link to="/" className="logo d-flex align-items-center">
                        <img src="Wlanetch Logo.png" alt="" />
                        {/* <span></span> */}
                    </Link>
                    <nav id="navbar" className={`navbar ${navbarMobile ? 'navbar-mobile' : ''}`}>
                        <ul>
                            <li>
                                <Link to="/" className={`nav-link scrollto ${location.pathname === '/' ? 'active' : ''}`}>
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link to="/about" className={`nav-link scrollto ${location.pathname === '/about' ? 'active' : ''}`}>
                                    About
                                </Link>
                            </li>
                            <li>
                                <Link to="/services" className={`nav-link scrollto ${location.pathname === '/services' ? 'active' : ''}`}>
                                    Services
                                </Link>
                            </li>
                            <li>
                                <Link to="/careers" className={`nav-link scrollto ${location.pathname === '/careers' ? 'active' : ''}`}>
                                    Careers
                                </Link>
                            </li>
                            <li>
                                <Link to="/contact" className={`nav-link scrollto ${location.pathname === '/contact' ? 'active' : ''}`}>
                                    Contact
                                </Link>
                            </li>
                            {/* <li><a className="getstarted scrollto" href="/">Get Started</a></li> */}
                        </ul>
                        <i onClick={handleToggle} className={`bi ${listIcon ? 'bi-list' : 'bi-x'} mobile-nav-toggle`}></i>
                    </nav>{/* .navbar */}
                </div>
            </header>
        </>
    )
}

export default Header;
