import React, { useEffect, useState } from 'react';
import { useMutation } from "@apollo/client";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { CONTACT_MUTATION } from '../graphQL/Mutations';

const ContactForm = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    const [showEmailAlert, setShowEmailAlert] = useState(false);
    const [showPhoneAlert, setShowPhoneAlert] = useState(false);

    const [inputField, setInputField] = useState({
        firstName: '',
        lastName: '',
        emailId: '',
        phoneNumber: '',
        contactSubject: '',
        contactMsg: '',
    });

    const inputHandler = (e, phone) => {
        let value = e.target.name === 'phoneNumber' ? phone : e.target.value;

        // Validation for first name and last name
        if (e.target.name === 'firstName' || e.target.name === 'lastName') {
            value = value.replace(/[^a-zA-Z]/g, ''); // Allow only alphabetic characters
        }

        // Validation for email
        if (e.target.name === 'emailId') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                // Invalid email format, show the email alert message
                setShowEmailAlert(true);
            } else {
                // Valid email format, hide the email alert message
                setShowEmailAlert(false);
            }
        }

        // Validation for phone number
        if (e.target.name === 'phoneNumber') {
            // Replace any non-digit characters
            const phoneNumber = value.replace(/\D/g, '');
            if (phoneNumber.length !== 12) {
                // Invalid phone number format, show the phone alert message
                setShowPhoneAlert(true);
            } else {
                // Valid phone number format, hide the phone alert message
                setShowPhoneAlert(false);
            }
        }

        setInputField({
            ...inputField,
            [e.target.name]: value,
        });
    };

    const isFormValid = () => {
        return Object.values(inputField).every(value => value.trim() !== '') && 
        !showEmailAlert &&
        !showPhoneAlert;
    };

    const [createContact, { error }] = useMutation(CONTACT_MUTATION);

    const formSubmit = async (e) => {
        e.preventDefault();
        try {
            const { data } = await createContact({
                variables: {
                    ...inputField,
                },
            });
            if (data) {
                setInputField({
                    firstName: '',
                    lastName: '',
                    emailId: '',
                    phoneNumber: '',
                    contactSubject: '',
                    contactMsg: '',
                });
                toast.success('Contact request sent successfully.');
            }

            if (error) {
                console.log(error);
            }

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            {/* ======= Contact Section ======= */}
            <section id="contact" className="contact">
                <div className="container" data-aos="fade-up">
                    <header className="section-header">
                        <h2>Contact</h2>
                        <p>Contact Us</p>
                    </header>
                    <div className="row gy-4">
                        <div className="col-lg-6">
                            <div className="row gy-4">
                                <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bi bi-geo-alt" />
                                        <h3>Address</h3>
                                        <p>Ahmedabad,<br />India</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bi bi-telephone" />
                                        <h3>Connect with us on</h3>
                                        <p>+91 82004 90200</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bi bi-envelope" />
                                        <h3>Email Us</h3>
                                        <p>wlanetech@gmail.com<br />careers@wlanetech.com</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bi bi-clock" />
                                        <h3>Business Hours</h3>
                                        <p>Monday - Friday<br />10:00AM - 06:00PM</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <form action="" className="php-email-form">
                                <div className="row gy-4">
                                    <div className="col-md-6">
                                        <input type="text" name="firstName" className="form-control" placeholder="First Name" onChange={inputHandler} value={inputField.firstName} required />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" name="lastName" className="form-control" placeholder="Last Name" onChange={inputHandler} value={inputField.lastName} required />
                                    </div>
                                    <div className="col-md-6 ">
                                        <input type="email" className="form-control" name="emailId" placeholder="Your Email" onChange={inputHandler} value={inputField.emailId} required />
                                        {showEmailAlert && (
                                            <div style={{ color: 'red', fontSize: '13px', paddingLeft:'2px' }}>Invalid email format</div>
                                        )}
                                    </div>
                                    <div className="col-md-6 pt-1">
                                        <PhoneInput
                                            name="phoneNumber"
                                            country={'in'}
                                            value={inputField.phoneNumber}
                                            onChange={(phone) => inputHandler({ target: { name: 'phoneNumber' } }, phone)}
                                            inputProps={{
                                                required: true,
                                            }}
                                            inputStyle={{ width: '100%' }}
                                        />
                                        {showPhoneAlert && (
                                            <div style={{ color: 'red', fontSize: '13px', paddingLeft:'2px' }}>Invalid number format</div>
                                        )}
                                    </div>
                                    <div className="col-md-12">
                                        <input type="text" className="form-control" name="contactSubject" placeholder="Subject" onChange={inputHandler} value={inputField.contactSubject} required />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea className="form-control" name="contactMsg" rows={3} placeholder="Message" onChange={inputHandler} value={inputField.contactMsg} required />
                                    </div>
                                    <div className="col-md-12 text-center">
                                        <div className="loading">Loading</div>
                                        <button type="button" onClick={formSubmit} disabled={!isFormValid()}>Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>{/* End Contact Section */}
            <ToastContainer />
        </>
    )
}

export default ContactForm;
