import React from 'react'
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
                {/* ======= Footer ======= */}
                <footer id="footer" className="footer">
                    <div className="footer-newsletter">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-12 text-center">
                                    <h4>Our Newsletter</h4>
                                    <p>Stay ahead in the ever-evolving tech landscape – Subscribe to our newsletter for the latest updates, trends, and innovations in the world of technology!</p>
                                </div>
                                <div className="col-lg-6">
                                    <form action='/'>
                                        <input type="email" name="email" disabled/><input type="submit" defaultValue="Subscribe" disabled />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-top">
                        <div className="container">
                            <div className="row gy-4">
                                <div className="col-lg-5 col-md-12 footer-info">
                                    <Link to="/" className="logo d-flex align-items-center">
                                        <img src="Wlanetch Logo.png" alt="" />
                                    </Link>
                                    <p></p>
                                    <div className="social-links mt-3 mx-2">
                                        <Link to="https://x.com/wlanetech?s=21" target='_blank' className="twitter"><i className="bi bi-twitter" /></Link>
                                        <Link to="https://www.facebook.com/profile.php?id=61555938057354&mibextid=2JQ9oc" target='_blank' className="facebook"><i className="bi bi-facebook" /></Link>
                                        <Link to="https://www.instagram.com/wlanetech/" target='_blank' className="instgram"><i className="bi bi-instagram" /></Link>
                                        <Link to="https://www.linkedin.com/in/7200-yash-patel/?originalSubdomain=in" target='_blank' className="linkedin"><i className="bi bi-linkedin" /></Link>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-6 footer-links">
                                    <h4>Useful Links</h4>
                                    <ul>
                                        <li><i className="bi bi-chevron-right" /> <Link to="/">Home</Link></li>
                                        <li><i className="bi bi-chevron-right" /> <Link to="/about">About</Link></li>
                                        <li><i className="bi bi-chevron-right" /> <Link to="/services">Services</Link></li>
                                        <li><i className="bi bi-chevron-right" /> <Link to="/careers">Careers</Link></li>
                                        <li><i className="bi bi-chevron-right" /> <Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                                <div className="col-lg-2 col-6 footer-links">
                                    <h4>Our Services</h4>
                                    <ul>
                                        <li><i className="bi bi-chevron-right" /> <Link to="/services">Digital Marketing</Link></li>
                                        <li><i className="bi bi-chevron-right" /> <Link to="/services">Web Development</Link></li>
                                        <li><i className="bi bi-chevron-right" /> <Link to="/services">Data Analysis</Link></li>
                                        <li><i className="bi bi-chevron-right" /> <Link to="/services">CRM Creation</Link></li>
                                        <li><i className="bi bi-chevron-right" /> <Link to="/services">Content Creation</Link></li>
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                                    <h4>Contact Us</h4>
                                    <p>
                                        Ahmedabad, Gujarat, India<br />
                                        <strong>Phone:</strong> +91 82004 90200<br />
                                        <strong>Email:</strong> wlanetech@gmail.com<br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="copyright">
                            © Copyright <strong><span>Wlanetech</span></strong>. All Rights Reserved
                        </div>
                    </div>
                </footer>{/* End Footer */}<p />
        </>
    )
}

export default Footer;