import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

import Header from './Header';
import Footer from './Footer';
import ContactForm from './ContactForm';

const Contact = () => {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <main id="main">
                {/* ======= Breadcrumbs ======= */}
                <section className="breadcrumbs">
                    <div className="container">
                        <ol>
                            <li><Link to="/home">Home</Link></li>
                            <li>Contact</li>
                        </ol>
                        <h2>Contact Us</h2>
                    </div>
                </section>{/* End Breadcrumbs */}
                <section className="inner-page">
                    {/* <div className="container"> */}
                        <p>
                            <ContactForm />
                        </p>
                    {/* </div> */}
                </section>
            </main>{/* End #main */}
            <Footer />
        </>
    )
}

export default Contact;
