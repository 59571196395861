import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

import Header from './Header';
import Footer from './Footer';
import ContactForm from './ContactForm';

const Home = () => {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <Header />
            {/* ======= Hero Section ======= */}
            <section id="hero" className="hero d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex flex-column justify-content-center">
                            <h1 data-aos="fade-up">Your Gateway to Cutting-edge IT and Digital Marketing Solutions</h1>
                            <h2 data-aos="fade-up" data-aos-delay={400}>We blend innovation with expertise to provide seamless IT and Digital Marketing services tailored to your unique needs.</h2>
                        </div>
                        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay={200}>
                            <img src="assets/img/hero-img.png" className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>{/* End Hero */}

            <main id="main">
                {/* ======= About Section ======= */}
                <section id="about" className="about">
                    <div className="container" data-aos="fade-up">
                        <div className="row gx-0">
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay={200}>
                                <div className="content">
                                    <h3>Who We Are</h3>
                                    <h2>Welcome to WLANETECH - The Solution Lane, where innovation
                                        meets expertise to create a digital ecosystem that propels
                                        businesses to new heights.</h2>
                                    <p>
                                        As a premier provider of IT and Digital Marketing services, we pride
                                        ourselves on being the architects of seamless technological solutions
                                        designed to empower your business for success. Our journey began with
                                        a commitment to bridging the gap between traditional business models
                                        and the ever-evolving digital realm.
                                    </p>
                                    <div className="text-center text-lg-start">
                                        <Link to="/about" className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                                            <span>Read More</span>
                                            <i className="bi bi-arrow-right" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay={200}>
                                <img src="assets/img/aboutUpdated.png" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </section>{/* End About Section */}

                {/* ======= Counts Section ======= */}
                <section id="counts" className="counts">
                    <div className="container" data-aos="fade-up">
                        <div className="row gy-4">
                            <div className="col-lg-3 col-md-6">
                                <div className="count-box">
                                    <i className="bi bi-emoji-smile" />
                                    <div>
                                        <span className="purecounter">50+</span>
                                        <p>Happy Clients</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="count-box">
                                    <i className="bi bi-journal-richtext" style={{ color: '#ee6c20' }} />
                                    <div>
                                        <span className="purecounter">30+</span>
                                        <p>Projects</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="count-box">
                                    <i className="bi bi-headset" style={{ color: '#15be56' }} />
                                    <div>
                                        <span className="purecounter">5+</span>
                                        <p>Years of Experience</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="count-box">
                                    <i className="bi bi-people" style={{ color: '#bb0852' }} />
                                    <div>
                                        <span className="purecounter">20+</span>
                                        <p>Skilled Experts</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>{/* End Counts Section */}

                {/* ======= Features Section ======= */}
                <section id="features" className="features">
                    <div className="container" data-aos="fade-up">
                        <header className="section-header">
                            <h2>Features</h2>
                            <p>Features That Sets WLANETECH Apart!!</p>
                        </header>
                        <div className="row">
                            <div className="col-lg-6">
                                <img src="assets/img/features.png" className="img-fluid" alt="" />
                            </div>
                            <div className="col-lg-6 mt-5 mt-lg-0 d-flex">
                                <div className="row align-self-center gy-4">
                                    <div className="col-md-6" data-aos="zoom-out" data-aos-delay={200}>
                                        <div className="feature-box d-flex align-items-center">
                                            <i className="bi bi-check" />
                                            <h3>Expertise that Transcends Boundaries</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6" data-aos="zoom-out" data-aos-delay={300}>
                                        <div className="feature-box d-flex align-items-center">
                                            <i className="bi bi-check" />
                                            <h3>Tailored Solutions, Unmatched Results</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6" data-aos="zoom-out" data-aos-delay={400}>
                                        <div className="feature-box d-flex align-items-center">
                                            <i className="bi bi-check" />
                                            <h3>Cutting-edge Technology</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6" data-aos="zoom-out" data-aos-delay={500}>
                                        <div className="feature-box d-flex align-items-center">
                                            <i className="bi bi-check" />
                                            <h3>Transparent Communication</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6" data-aos="zoom-out" data-aos-delay={600}>
                                        <div className="feature-box d-flex align-items-center">
                                            <i className="bi bi-check" />
                                            <h3>Available For Support</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6" data-aos="zoom-out" data-aos-delay={700}>
                                        <div className="feature-box d-flex align-items-center">
                                            <i className="bi bi-check" />
                                            <h3>Customized Solutions</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> {/* / row */}
                    </div>
                </section>{/* End Features Section */}

                {/* ======= Services Section ======= */}
                <section id="services" className="services">
                    <div className="container" data-aos="fade-up">
                        <header className="section-header">
                            <h2>Services</h2>
                            <p>Just Say The Word, We Can Do It All!!</p>
                        </header>
                        <div className="row gy-4">
                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={200}>
                                <div className="service-box blue">
                                    <i className="ri-discuss-line icon" />
                                    <h3>Web Design & Development</h3>
                                    <p>Seamless web development
                                        solutions, crafting digital excellence for online success.
                                    </p>
                                    <Link to="/services" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={300}>
                                <div className="service-box orange">
                                    <i className="ri-discuss-line icon" />
                                    <h3>Digital Marketing</h3>
                                    <p>Boost your online presence and maximize ROI
                                        with our data-driven digital marketing service.
                                    </p>
                                    <Link to="/services" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={400}>
                                <div className="service-box green">
                                    <i className="ri-discuss-line icon" />
                                    <h3>Data Analysis</h3>
                                    <p>Uncovering valuable insights and patterns
                                        through meticulous data analysis, driving informed
                                        decision-making for business success.
                                    </p>
                                    <Link to="/services" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>{/* End Services Section */}

                {/* ======= F.A.Q Section ======= */}
                <section id="faq" className="faq">
                    <div className="container" data-aos="fade-up">
                        <header className="section-header">
                            <h2>F.A.Q</h2>
                            <p>Frequently Asked Questions</p>
                        </header>
                        <div className="row">
                            <div className="col-lg-6">
                                {/* F.A.Q List 1*/}
                                <div className="accordion accordion-flush" id="faqlist1">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                                                What sets WLANETECH apart from other IT and Digital Marketing firms?
                                            </button>
                                        </h2>
                                        <div id="faq-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                            <div className="accordion-body">
                                                Our holistic approach integrates IT and Digital Marketing seamlessly, providing a unified solution for your business needs.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                                                How do you ensure data security for your clients?
                                            </button>
                                        </h2>
                                        <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                            <div className="accordion-body">
                                                We implement robust cybersecurity measures, adhere to industry best practices, and conduct regular security audits to safeguard your data.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                                                Can you customize your services according to our business requirements?
                                            </button>
                                        </h2>
                                        <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                            <div className="accordion-body">
                                                Absolutely! Our services are tailored to meet the unique demands of your business, ensuring a personalized and effective solution.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                {/* F.A.Q List 2*/}
                                <div className="accordion accordion-flush" id="faqlist2">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-1">
                                                Can you customize your services according to our business requirements?
                                            </button>
                                        </h2>
                                        <div id="faq2-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                                            <div className="accordion-body">
                                                Absolutely! Our services are tailored to meet the unique demands of your business, ensuring a personalized and effective solution.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-2">
                                                What is the expected turnaround time for projects?
                                            </button>
                                        </h2>
                                        <div id="faq2-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                                            <div className="accordion-body">
                                                Turnaround times vary based on the complexity of the project. We discuss timelines transparently during the initial consultation, ensuring alignment with your expectations.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-3">
                                                How does WLANETECH approach Search Engine Optimization (SEO) to improve online visibility?
                                            </button>
                                        </h2>
                                        <div id="faq2-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                                            <div className="accordion-body">
                                                We conduct comprehensive keyword research, optimize website content, ensure technical SEO compliance, and implement strategic link-building campaigns.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>{/* End F.A.Q Section */}
                <ContactForm />
            </main >

            <Footer />
        </>
    )
}

export default Home;
