import { gql } from "@apollo/client";

export const CONTACT_MUTATION = gql`
mutation CreateContact($firstName: String!, $lastName: String!, $emailId: String!, $phoneNumber: String!, $contactSubject: String!, $contactMsg: String!) {
  createContact(firstName: $firstName, lastName: $lastName, emailId: $emailId, phoneNumber: $phoneNumber, contactSubject: $contactSubject, contactMsg: $contactMsg) {
    contactId
  }
}
`

export const CAREER_MUTATION = gql`
mutation CreateApplication($firstName: String!, $lastName: String!, $phoneNumber: String!, $emailId: String!, $addrLineOne: String!, $addrLineTwo: String!, $city: String!, $state: String!, $pincode: String!, $highLvlOfEdu: String!, $degreeName: String!, $nameOfInstitute: String!, $prevCompanyName: String!, $prevJobRole: String!, $prevDoj: String!, $prevTillDate: String!, $positionApplyFor: String!, $referredPersonName: String!, $referredPersonContact: String!) {
  createApplication(firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, emailId: $emailId, addrLineOne: $addrLineOne, addrLineTwo: $addrLineTwo, city: $city, state: $state, pincode: $pincode, highLvlOfEdu: $highLvlOfEdu, degreeName: $degreeName, nameOfInstitute: $nameOfInstitute, prevCompanyName: $prevCompanyName, prevJobRole: $prevJobRole, prevDoj: $prevDoj, prevTillDate: $prevTillDate, positionApplyFor: $positionApplyFor, referredPersonName: $referredPersonName, referredPersonContact: $referredPersonContact) {
    applicationId
  }
}
`
