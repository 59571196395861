import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const uri = process.env.REACT_APP_GRAPHQL_ENDPOINT;

const httpLink = createHttpLink ({
    uri: uri
});

const authLink = setContext((_, { header }) => {
    return {
        header: {
            ...header,
            // authorization: localStorage.getItem("token") || ""
        }
    }
});

const client = new ApolloClient ({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

export default client;
