import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";

import './App.css';
import client from "./ApolloClient";
import Home from './components/Home';
import Contact from "./components/Contact";
import About from "./components/About";
import Services from "./components/Services";
import Careers from "./components/Careers";

function App() {
  return (
    <>
      <ApolloProvider client={client}>
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/services" element={<Services />} />
            <Route exact path="/careers" element={<Careers />} />
            <Route exact path="/contact" element={<Contact />} />
          </Routes>
        </Router>
      </ApolloProvider>
    </>
  );
}

export default App;
