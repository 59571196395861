import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

import Header from './Header';
import Footer from './Footer';
import ContactForm from './ContactForm';

const Services = () => {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <Header />
            <main id="main">
                {/* ======= Breadcrumbs ======= */}
                <section className="breadcrumbs">
                    <div className="container">
                        <ol>
                            <li><Link to="/home">Home</Link></li>
                            <li>Services</li>
                        </ol>
                        <h2>Our Services</h2>
                    </div>
                </section>{/* End Breadcrumbs */}
                <section className="inner-page">
                    {/* <div className="container"> */}
                    {/* ======= Services Section ======= */}
                    <section id="services" className="services">
                        <div className="container" data-aos="fade-up">
                            <header className="section-header">
                                <h2>Services</h2>
                                <p>Explore a wide range of services we offer to skyrocket your business.</p>
                            </header>
                            <div className="row gy-4">
                                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={200}>
                                    <div className="service-box blue">
                                        <i className="ri-discuss-line icon" />
                                        <h3>Web Design & Development</h3>
                                        <p>Seamless web development
                                            solutions, crafting digital excellence for online success.
                                        </p>
                                        {/* <a href="/" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></a> */}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={300}>
                                    <div className="service-box orange">
                                        <i className="ri-discuss-line icon" />
                                        <h3>Digital Marketing</h3>
                                        <p>Boost your online presence and maximize ROI
                                            with our data-driven digital marketing service.
                                        </p>
                                        {/* <a href="/" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></a> */}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={400}>
                                    <div className="service-box green">
                                        <i className="ri-discuss-line icon" />
                                        <h3>Data Analysis</h3>
                                        <p>Uncovering valuable insights and patterns
                                            through meticulous data analysis, driving informed
                                            decision-making for business success.
                                        </p>
                                        {/* <a href="/" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></a> */}
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                                    <div className="service-box red">
                                        <i className="ri-discuss-line icon"></i>
                                        <h3>Data Visualization</h3>
                                        <p>Transforming complex data into compelling visual stories, revealing patterns and trends for better understanding and decision-making.</p>
                                        {/* <a href="/" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a> */}
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
                                    <div className="service-box purple">
                                        <i className="ri-discuss-line icon"></i>
                                        <h3>CRM Creation</h3>
                                        <p>Building customised CRM solutions that streamline processes, enhance customer relationships, and drive business growth.</p>
                                        {/* <a href="/" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a> */}
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="700">
                                    <div className="service-box pink">
                                        <i className="ri-discuss-line icon"></i>
                                        <h3>Social Media Management</h3>
                                        <p>Streamline your online presence and engage your audience with our expert social media management service.</p>
                                        {/* <a href="/" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a> */}
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={200}>
                                    <div className="service-box blue">
                                        <i className="ri-discuss-line icon" />
                                        <h3>Search Engine Optimization</h3>
                                        <p>Boosting online visibility and organic traffic with expert SEO strategies, maximizing brand reach and growth.</p>
                                        {/* <a href="/" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></a> */}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={300}>
                                    <div className="service-box orange">
                                        <i className="ri-discuss-line icon" />
                                        <h3>Branding</h3>
                                        <p>Elevate your brand presence with us where strategic design meets compelling storytelling to create a lasting and impactful brand identity</p>
                                        {/* <a href="/" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></a> */}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={400}>
                                    <div className="service-box green">
                                        <i className="ri-discuss-line icon" />
                                        <h3>Graphic Designing</h3>
                                        <p>Transforming visions into captivating visuals - Unleashing creativity through impeccable graphic design solutions.</p>
                                        {/* <a href="/" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>{/* End Services Section */}
                    <p>
                        <ContactForm />
                    </p>
                    {/* </div> */}
                </section>
            </main>{/* End #main */}
            <Footer />
        </>
    )
}

export default Services;
