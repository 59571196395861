import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

import Header from './Header';
import Footer from './Footer';
import CareerForm from './CareerForm';

const Careers = () => {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);

  return (
    <>
    <Header />
            <main id="main">
                {/* ======= Breadcrumbs ======= */}
                <section className="breadcrumbs">
                    <div className="container">
                        <ol>
                            <li><Link to="/home">Home</Link></li>
                            <li>Careers</li>
                        </ol>
                        <h2>Careers</h2>
                    </div>
                </section>{/* End Breadcrumbs */}
                <section className="inner-page">
                    <p>
                        <CareerForm />
                    </p>
                </section>
            </main>{/* End #main */}
            <Footer />
    </>
  )
}

export default Careers;
