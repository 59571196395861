import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

import Header from './Header';
import Footer from './Footer';
import ContactForm from './ContactForm';

const About = () => {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <Header />
            <main id="main">
                {/* ======= Breadcrumbs ======= */}
                <section className="breadcrumbs">
                    <div className="container">
                        <ol>
                            <li><Link to="/home">Home</Link></li>
                            <li>About</li>
                        </ol>
                        <h2>About Us</h2>
                    </div>
                </section>{/* End Breadcrumbs */}

                <section className="inner-page">
                    {/* <div className="container"> */}
                    {/* ======= About Section ======= */}
                    <section id="about" className="about">
                        <div className="container" data-aos="fade-up">
                            <div className="row gx-0">
                                <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay={200}>
                                    <div className="content">
                                        <h3>Our Story</h3>
                                        <h2>Established with a vision to redefine the digital landscape, WLANETECH
was founded by a team of seasoned professionals with a passion for
leveraging technology to drive business excellence. Our journey began
with a commitment to bridging the gap between traditional business
models and the ever-evolving digital realm.</h2>
                                        <p>
                                        Driven by a spirit of innovation and a relentless pursuit of excellence,
WLANETECH has evolved into a dynamic force in the IT and Digital
Marketing domain. Our journey is a testament to our dedication to
staying ahead of the curve, adapting to industry trends, and consistently
delivering value to our clients.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay={200}>
                                    <img src="assets/img/aboutUpdated.png" className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </section>{/* End About Section */}


                    {/* ======= Values Section ======= */}
                    <section id="values" className="values">
                        <div className="container" data-aos="fade-up">
                            <header className="section-header">
                                <h2>Our Values</h2>
                                <p>Why Choose Us</p>
                            </header>
                            <div className="row">
                                <div className="col-lg-4" data-aos="fade-up" data-aos-delay={200}>
                                    <div className="box">
                                        <img src="assets/img/values-1.png" className="img-fluid" alt="" />
                                        <h3>Holistic Approach</h3>
                                        <p>We integrate IT and Digital Marketing seamlessly, offering a comprehensive suite of services that cater to all aspects of your digital presence</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay={400}>
                                    <div className="box">
                                        <img src="assets/img/values-2.png" className="img-fluid" alt="" />
                                        <h3>Innovation at Core</h3>
                                        <p>Stay ahead in the digital race with our commitment to incorporating the latest technologies and industry best practices.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay={600}>
                                    <div className="box">
                                        <img src="assets/img/values-3.png" className="img-fluid" alt="" />
                                        <h3>Client-Centric Approach</h3>
                                        <p>Your success is our priority. We believe in transparent communication, collaborative problem-solving, and delivering measurable results.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>{/* End Values Section */}

                    {/* ======= Features Section ======= */}
                    <section id="features" className="features">
                        <div className="container" data-aos="fade-up">
                            {/* <header className="section-header">
                                <h2>Features</h2>
                                <p>Laboriosam et omnis fuga quis dolor direda fara</p>
                            </header> */}
                            {/* Feature Tabs */}
                            {/* <div className="row feture-tabs" data-aos="fade-up">
                                <div className="col-lg-6">
                                    <h3>Neque officiis dolore maiores et exercitationem quae est seda lidera pat claero</h3>
                                    <ul className="nav nav-pills mb-3">
                                        <li>
                                            <a className="nav-link active" data-bs-toggle="pill" href="#tab1">Saepe fuga</a>
                                        </li>
                                        <li>
                                            <a className="nav-link" data-bs-toggle="pill" href="#tab2">Voluptates</a>
                                        </li>
                                        <li>
                                            <a className="nav-link" data-bs-toggle="pill" href="#tab3">Corrupti</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane fade show active" id="tab1">
                                            <p>Consequuntur inventore voluptates consequatur aut vel et. Eos doloribus expedita. Sapiente atque consequatur minima nihil quae aspernatur quo suscipit voluptatem.</p>
                                            <div className="d-flex align-items-center mb-2">
                                                <i className="bi bi-check2" />
                                                <h4>Repudiandae rerum velit modi et officia quasi facilis</h4>
                                            </div>
                                            <p>Laborum omnis voluptates voluptas qui sit aliquam blanditiis. Sapiente minima commodi dolorum non eveniet magni quaerat nemo et.</p>
                                            <div className="d-flex align-items-center mb-2">
                                                <i className="bi bi-check2" />
                                                <h4>Incidunt non veritatis illum ea ut nisi</h4>
                                            </div>
                                            <p>Non quod totam minus repellendus autem sint velit. Rerum debitis facere soluta tenetur. Iure molestiae assumenda sunt qui inventore eligendi voluptates nisi at. Dolorem quo tempora. Quia et perferendis.</p>
                                        </div>
                                        <div className="tab-pane fade show" id="tab2">
                                            <p>Consequuntur inventore voluptates consequatur aut vel et. Eos doloribus expedita. Sapiente atque consequatur minima nihil quae aspernatur quo suscipit voluptatem.</p>
                                            <div className="d-flex align-items-center mb-2">
                                                <i className="bi bi-check2" />
                                                <h4>Repudiandae rerum velit modi et officia quasi facilis</h4>
                                            </div>
                                            <p>Laborum omnis voluptates voluptas qui sit aliquam blanditiis. Sapiente minima commodi dolorum non eveniet magni quaerat nemo et.</p>
                                            <div className="d-flex align-items-center mb-2">
                                                <i className="bi bi-check2" />
                                                <h4>Incidunt non veritatis illum ea ut nisi</h4>
                                            </div>
                                            <p>Non quod totam minus repellendus autem sint velit. Rerum debitis facere soluta tenetur. Iure molestiae assumenda sunt qui inventore eligendi voluptates nisi at. Dolorem quo tempora. Quia et perferendis.</p>
                                        </div>
                                        <div className="tab-pane fade show" id="tab3">
                                            <p>Consequuntur inventore voluptates consequatur aut vel et. Eos doloribus expedita. Sapiente atque consequatur minima nihil quae aspernatur quo suscipit voluptatem.</p>
                                            <div className="d-flex align-items-center mb-2">
                                                <i className="bi bi-check2" />
                                                <h4>Repudiandae rerum velit modi et officia quasi facilis</h4>
                                            </div>
                                            <p>Laborum omnis voluptates voluptas qui sit aliquam blanditiis. Sapiente minima commodi dolorum non eveniet magni quaerat nemo et.</p>
                                            <div className="d-flex align-items-center mb-2">
                                                <i className="bi bi-check2" />
                                                <h4>Incidunt non veritatis illum ea ut nisi</h4>
                                            </div>
                                            <p>Non quod totam minus repellendus autem sint velit. Rerum debitis facere soluta tenetur. Iure molestiae assumenda sunt qui inventore eligendi voluptates nisi at. Dolorem quo tempora. Quia et perferendis.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <img src="assets/img/features-2.png" className="img-fluid" alt="" />
                                </div>
                            </div> */}
                            {/* End Feature Tabs */} 
                            {/* Feature Icons */}
                            <div className="row feature-icons" data-aos="fade-up">
                                <h3>Process We Follow</h3>
                                <div className="row">
                                    <div className="col-xl-4 text-center" data-aos="fade-right" data-aos-delay={100}>
                                        <img src="assets/img/features-3.png" className="img-fluid p-4" alt="" />
                                    </div>
                                    <div className="col-xl-8 d-flex content">
                                        <div className="row align-self-center gy-4">
                                            <div className="col-md-6 icon-box" data-aos="fade-up">
                                                <i className="ri-line-chart-line" />
                                                <div>
                                                    <h4>Discovery & Consultation</h4>
                                                    <p>We begin by getting to know you – your business, goals, and challenges.
Through in-depth consultations, we gain a comprehensive understanding
of your vision, allowing us to tailor our solutions to your unique needs.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay={100}>
                                                <i className="ri-stack-line" />
                                                <div>
                                                    <h4>Strategic Planning</h4>
                                                    <p>Armed with insights from the discovery phase, our team formulates a
strategic plan. Whether it's devising an IT infrastructure strategy or
mapping out a digital marketing campaign, every step is meticulously
planned to align with your objectives.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay={200}>
                                                <i className="ri-brush-4-line" />
                                                <div>
                                                    <h4>Design & Development</h4>
                                                    <p>This phase is where ideas come to life. From designing intuitive user
interfaces to developing robust IT solutions, our team of experts
combines creativity with technical precision. We keep you involved
throughout, ensuring the end result aligns perfectly with your
expectations.
</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay={300}>
                                                <i className="ri-magic-line" />
                                                <div>
                                                    <h4>Implementation & Testing</h4>
                                                    <p>Rigorous testing is an integral part of our process. Before deployment,
we conduct thorough quality assurance to identify and address any
potential issues. This ensures that the final product not only meets but
exceeds industry standards.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay={400}>
                                                <i className="ri-command-line" />
                                                <div>
                                                    <h4>Deployment & Integration</h4>
                                                    <p>With everything tested and approved, we move to deployment. Our team
ensures a smooth transition, integrating new solutions seamlessly into
your existing infrastructure. Whether it's launching a website or
implementing a new IT system, minimal disruption is our priority.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay={500}>
                                                <i className="ri-radar-line" />
                                                <div>
                                                    <h4>Monitoring & Optimization</h4>
                                                    <p>Our commitment doesn't end with deployment. We continuously monitor
performance, analyze data, and identify areas for optimization. This
proactive approach ensures that your digital assets evolve with the
dynamic business landscape.
</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* End Feature Icons */}
                        </div>
                    </section>{/* End Features Section */}

                    <p>
                        <ContactForm />
                    </p>
                    {/* </div> */}
                </section>
            </main>{/* End #main */}
            <Footer />
        </>
    )
}

export default About;
