import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from "@apollo/client";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { LOAD_JOBS } from '../graphQL/Queries';
import { CAREER_MUTATION } from '../graphQL/Mutations';

const CareerForm = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    const [showEmailAlert, setShowEmailAlert] = useState(false);
    const [showPhoneAlert, setShowPhoneAlert] = useState(false);
    const [showPinAlert, setShowPinAlert] = useState(false);

    // get all Jobs
    const { error, loading, data } = useQuery(LOAD_JOBS);
    const [jobs, setJobs] = useState([])
    useEffect(() => {
        if (error) {
            console.log(error);
        }
        if (loading) {
            console.log('Loading');
        }
        if (data) {
            console.log(data.jobs);
            setJobs(data.jobs);
        }
    }, [data, error, loading]);

    const [inputField, setInputField] = useState({
        applicationId: '',
        firstName: '',
        lastName: '',
        emailId: '',
        phoneNumber: '',
        addrLineOne: '',
        addrLineTwo: '',
        city: '',
        state: '',
        pincode: '',
        highLvlEdu: '',
        degreeName: '',
        nameOfInstitute: '',
        prevCompanyName: '',
        prevJobRole: '',
        prevDoj: '',
        prevTillDate: '',
        positionApplyFor: '',
        // resumeFileLoc: '',
        referredPersonName: '',
        referredPersonContact: ''
    });

    const inputHandler = (e, phone) => {
        let value = e.target.name === 'phoneNumber' ? phone : e.target.value;

        // Validation to Allow only alphabetic characters
        if (e.target.name === 'firstName' || e.target.name === 'lastName' || e.target.name === 'city' || e.target.name === 'state') {
            value = value.replace(/[^a-zA-Z]/g, '');
        }

        // Validation to Allow only alphabetic characters with space
        if (e.target.name === 'nameOfInstitute' || e.target.name === 'prevCompanyName' || e.target.name === 'referredPersonName') {
            value = value.replace(/[^a-zA-Z\s]/g, '');
        }

        // Validation for email
        if (e.target.name === 'emailId') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                // Invalid email format, show the email alert message
                setShowEmailAlert(true);
            } else {
                // Valid email format, hide the email alert message
                setShowEmailAlert(false);
            }
        }

        // Validation for phone number
        if (e.target.name === 'phoneNumber') {
            // Replace any non-digit characters
            const phoneNumber = value.replace(/\D/g, '');
            if (phoneNumber.length !== 12) {
                // Invalid phone number format, show the phone alert message
                setShowPhoneAlert(true);
            } else {
                // Valid phone number format, hide the phone alert message
                setShowPhoneAlert(false);
            }
        }

        // Validation for pincode
        if (e.target.name === 'pincode') {
            const pincodeRegex = /^\d{6}$/; // 6-digit PIN code regex
            if (!pincodeRegex.test(value)) {
                // Invalid pincode format, show the pincode alert message
                setShowPinAlert(true);
            } else {
                // Valid pincode format, hide the pincode alert message
                setShowPinAlert(false);
            }
        }

        setInputField({
            ...inputField,
            [e.target.name]: value,
        });
    };

    const isFormValid = () => {
        return Object.values(inputField).every(value => value.trim() !== '') &&
            !showEmailAlert &&
            !showPhoneAlert &&
            !showPinAlert;
    };

    const [createApplication, { createAppError }] = useMutation(CAREER_MUTATION);

    const formSubmit = async (e) => {
        e.preventDefault();
        try {
            const { data } = await createApplication({
                variables: {
                    ...inputField,
                },
            });
            if (data) {
                console.log(inputField);
                setInputField({
                    applicationId: '',
                    firstName: '',
                    lastName: '',
                    emailId: '',
                    phoneNumber: '',
                    addrLineOne: '',
                    addrLineTwo: '',
                    city: '',
                    state: '',
                    pincode: '',
                    highLvlEdu: '',
                    degreeName: '',
                    nameOfInstitute: '',
                    prevCompanyName: '',
                    prevJobRole: '',
                    prevDoj: '',
                    prevTillDate: '',
                    positionApplyFor: '',
                    // resumeFileLoc: '',
                    referredPersonName: '',
                    referredPersonContact: ''
                });
                toast.success('Application request sent successfully.');
            }

            if (createAppError) {
                console.log(createAppError);
            }

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            {/* ======= Contact Section ======= */}
            <section id="contact" className="contact">
                <div className="container" data-aos="fade-up">
                    <header className="section-header">
                        <h2>Career</h2>
                        <p>Apply Here</p>
                    </header>
                    <div className="row gy-4">
                        <form action="" className="php-email-form">
                            <div className="row gy-4">
                                <span style={{ color: '#012970', fontWeight: 700 }}>Personal Information <span style={{ color: 'red', fontWeight: 800 }}><superscript>*</superscript></span> </span>
                                <div className="col-md-6">
                                    <input type="text" name="firstName" className="form-control" placeholder="First Name" onChange={inputHandler} value={inputField.firstName} required />
                                </div>
                                <div className="col-md-6">
                                    <input type="text" name="lastName" className="form-control" placeholder="Last Name" onChange={inputHandler} value={inputField.lastName} required />
                                </div>
                                <div className="col-md-6 ">
                                    <input type="email" className="form-control" name="emailId" placeholder="Your Email" onChange={inputHandler} value={inputField.emailId} required />
                                    {showEmailAlert && (
                                        <div style={{ color: 'red', fontSize: '13px', paddingLeft:'2px' }}>Invalid email format</div>
                                    )}
                                </div>
                                <div className="col-md-6 pt-1">
                                    <PhoneInput
                                        name="phoneNumber"
                                        country={'in'}
                                        value={inputField.phoneNumber}
                                        onChange={(phone) => inputHandler({ target: { name: 'phoneNumber' } }, phone)}
                                        inputProps={{
                                            required: true,
                                        }}
                                        inputStyle={{ width: '100%' }}
                                    />
                                    {showPhoneAlert && (
                                        <div style={{ color: 'red', fontSize: '13px', paddingLeft: '2px' }}>Invalid number format</div>
                                    )}
                                </div>

                                <span style={{ color: '#012970', fontWeight: 700 }}>Address Details <span style={{ color: 'red', fontWeight: 800 }}><superscript>*</superscript></span> </span>
                                <div className="col-md-12">
                                    <textarea className="form-control" name="addrLineOne" rows={2} placeholder="Address Line 1" onChange={inputHandler} value={inputField.addrLineOne} required defaultValue={""} />
                                </div>
                                <div className="col-md-12">
                                    <textarea className="form-control" name="addrLineTwo" rows={2} placeholder="Address Line 2" onChange={inputHandler} value={inputField.addrLineTwo} required defaultValue={""} />
                                </div>
                                <div className="col-md-4 ">
                                    <input type="text" className="form-control" name="city" placeholder="City" onChange={inputHandler} value={inputField.city} required />
                                </div>
                                <div className="col-md-4 ">
                                    <input type="text" className="form-control" name="state" placeholder="State" onChange={inputHandler} value={inputField.state} required />
                                </div>
                                <div className="col-md-4 ">
                                    <input type="text" className="form-control" name="pincode" placeholder="Pincode" onChange={inputHandler} value={inputField.pincode} required />
                                    {showPinAlert && (
                                        <div style={{ color: 'red', fontSize: '13px', paddingLeft: '2px' }}>Invalid pincode format</div>
                                    )}
                                </div>

                                <span style={{ color: '#012970', fontWeight: 700 }}>Education Details <span style={{ color: 'red', fontWeight: 800 }}><superscript>*</superscript></span> </span>
                                <div className="col-md-6 ">
                                    <select name="highLvlOfEdu" className="form-control" onChange={inputHandler}>
                                        <option value="">Select highest level of education</option>
                                        <option value="10th">10th</option>
                                        <option value="12th">12th</option>
                                        <option value="Graduate">Graduate</option>
                                        <option value="Post Graduate">Post Graduate</option>
                                        <option value="Doctoral/PhD">Doctoral/PhD</option>
                                    </select>
                                </div>
                                <div className="col-md-6 ">
                                    <input type="text" className="form-control" name="degreeName" placeholder="Degree Name" onChange={inputHandler} value={inputField.degreeName} required />
                                </div>
                                <div className="col-md-12">
                                    <input type="text" className="form-control" name="nameOfInstitute" placeholder="Name of Insititute" onChange={inputHandler} value={inputField.nameOfInstitute} required />
                                </div>

                                <span style={{ color: '#012970', fontWeight: 700 }}>Previous Company Details</span>
                                <div className="col-md-12">
                                    <input type="text" className="form-control" name="prevCompanyName" placeholder="Company Name" onChange={inputHandler} value={inputField.prevCompanyName} required />
                                </div>
                                <div className="col-md-12">
                                    <input type="text" className="form-control" name="prevJobRole" placeholder="Designation / Job Role" onChange={inputHandler} value={inputField.prevJobRole} required />
                                </div>
                                <span style={{ color: '#012970', fontWeight: 400 }}>Period [From - To]</span>
                                <div className="col-md-6">
                                    <input type="date" className="form-control" name="prevDoj" onChange={inputHandler} value={inputField.prevDoj} required />
                                </div>
                                <div className="col-md-6">
                                    <input type="date" className="form-control" name="prevTillDate" onChange={inputHandler} value={inputField.prevTillDate} required />
                                </div>

                                <span style={{ color: '#012970', fontWeight: 700 }}>Applying Position Details <span style={{ color: 'red', fontWeight: 800 }}><superscript>*</superscript></span> </span>
                                <div className="col-md-12">
                                    <select name="positionApplyFor" className="form-control" onChange={inputHandler}>
                                        <option value="" disabled selected>Select Job Role</option>
                                        {
                                            jobs.map((val, index) => {
                                                return <option value={val.jobCode - val.jobTitle}>{val.jobCode} - {val.jobTitle}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                {/* <div className="col-md-12">
                                    <input type="file" className="form-control" name="resumeFileLoc" placeholder="Resume" onChange={inputHandler} value={inputField.resumeFileLoc} required />
                                </div> */}
                                <div className="col-md-6">
                                    <input type="text" className="form-control" name="referredPersonName" placeholder="Referred Person Name" onChange={inputHandler} value={inputField.referredPersonName} required />
                                </div>
                                <div className="col-md-6">
                                    <input type="text" className="form-control" name="referredPersonContact" placeholder="Referred Person Contact/email" onChange={inputHandler} value={inputField.referredPersonContact} required />
                                </div>

                                <div className="col-md-12 text-center">
                                    <div className="loading">Loading</div>
                                    <button type="button" onClick={formSubmit}>Submit Application</button>
                                </div>
                            </div>
                        </form>
                        {/* </div> */}
                    </div>
                </div>
            </section>{/* End Contact Section */}
            <ToastContainer />
        </>
    )
}

export default CareerForm
